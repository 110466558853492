<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref, watch } from 'vue'
import { getContests, updateContest } from './api'
import AppPagination from '@/components/app-pagination'
import Notify from '@/notify'
import debounce from '@/utils/debounce'
import SelectSeller from '@/components/select-seller'
import SelectCustomer from '@/components/select-customer'

const statusMap = {
  FINISHED: {
    label: 'Finalizado',
    class: 'badge-soft-danger'
  },
  ACTIVE: {
    label: 'Ativo',
    class: 'badge-soft-success'
  },
  STANDBY: {
    label: 'Em pausa',
    class: 'badge-soft-info'
  },
  PAYMENT: {
    label: 'Aguardando Pagamento',
    class: 'badge-soft-warning'
  }
}

export default {
  setup () {
    const contestsList = ref([])
    const updating = ref([])
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const filter = ref({
      title: null,
      status: null,
      user_id: null,
      winner_id: null
    })
    onMounted(() => {
      fetchContests()
    })

    const fetchContests = debounce(() => {
      const params = {
        page: page.value,
        title: filter.value.title,
        status: filter.value.status,
        user_id: filter.value?.user_id?.value,
        winner_id: filter.value?.winner_id?.value,
        order: {
          created_at: 'desc'
        }
      }
      getContests(params).then((data) => {
        contestsList.value = data.data
        pages.value = data.last_page
        total.value = data.total
      }).catch(() => {
        Notify.create({
          title: 'Falha ao carregar os sorteios',
          timer: 1500
        })
      })
    }, 1000)

    watch(filter, () => {
      page.value = 1
      fetchContests()
    }, { deep: true })

    const statusGet = (status, key) => {
      if (statusMap[status]) {
        return statusMap[status][key] ?? 'N/I'
      }
      return 'NotIdentified'
    }

    const onChangeTitle = debounce((contest) => {
      if (!isUpdating(contest)) {
        updating.value.push(contest.id)
        updateContest(contest.id, {
          title: contest.title
        }).then(() => {
          const index = updating.value.indexOf(contest.id)
          updating.value.splice(index, 1)
        })
      }
    }, 750)

    const isUpdating = ({ id }) => updating.value.includes(id)

    return {
      page,
      pages,
      contestsList,
      statusGet,
      fetchContests,
      filter,
      total,
      statusMap,
      onChangeTitle,
      isUpdating
    }
  },
  components: {
    SelectCustomer,
    AppPagination,
    Layout,
    SelectSeller
  }
}

</script>

<template>
  <Layout :pagetitle="'Sorteios'">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-lg-6 mb-3">
                <input
                  v-model="filter.title"
                  type="text"
                  class="form-control"
                  placeholder="Título"
                >
              </div>
              <div class="col-sm-12 col-lg-6 mb-3">
                <select
                  v-model="filter.status"
                  class="form-select"
                >
                  <option :value="null">
                    Status
                  </option>
                  <option
                    v-for="(status, key) in statusMap"
                    :key="key"
                    :value="key"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-lg-6 mb-3">
                <select-seller
                  v-model="filter.user_id"
                  placeholder="Pesquisar por vendedor"
                />
              </div>
              <div class="col-sm-12 col-lg-6 mb-3">
                <select-customer
                  v-model="filter.winner_id"
                  placeholder="Pesquisar por vencedor"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-striped mb-4"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      Vendedor
                    </th>
                    <th scope="col">
                      Título
                    </th>
                    <th scope="col">
                      Preço
                    </th>
                    <th scope="col">
                      Números
                    </th>
                    <th scope="col">
                      Data cadastro
                    </th>
                    <th scope="col">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="contest in contestsList"
                    :key="contest.id"
                  >
                    <td>{{ contest.user? contest.user : 'N/I' }}</td>
                    <td>
                      <b-input-group
                        :append="isUpdating(contest) ? '...' : 'OK'"
                      >
                        <b-form-input
                          v-model="contest.title"
                          placeholder="Título"
                          debounce="500"
                          :readonly="isUpdating(contest)"
                          @input="onChangeTitle(contest)"
                        />
                      </b-input-group>
                    </td>
                    <td>{{ contest.price? $n(contest.price, 'currency') : '' }}</td>
                    <td>{{ contest.quantity }}</td>
                    <td>{{ contest.created_at? $d(contest.created_at, 'short') : '' }}</td>
                    <td>
                      <span
                        class="badge rounded-pill"
                        :class="statusGet(contest.status, 'class')"
                      >
                        {{ statusGet(contest.status, 'label') }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-pagination
                v-model="page"
                class="pagination"
                :pages="pages"
                :range-size="1"
                @update:modelValue="fetchContests"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
